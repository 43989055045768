@import "primeicons/primeicons.css";
:root {
  --bs-blue: #168bde;
  --bs-red: #ff4d5b;
  --bs-green: #84bd23;
  --bs-gray: #879698;
  --bs-gray-dark: #575a5d;
  --bs-gray-600: #879698;
  --bs-gray-700: #5a6268;
  --bs-gray-800: #575a5d;
  --bs-primary: #84bd23;
  --bs-secondary: #dbeac4;
  --bs-success: #c4de99;
  --bs-info: #168bde;
  --bs-danger: #e1e000;
  --bs-light: #fff;
  --bs-dark: #000;
  --bs-primary-rgb: 132, 189, 35;
  --bs-secondary-rgb: 219, 234, 196;
  --bs-success-rgb: 196, 222, 153;
  --bs-info-rgb: 22, 139, 222;
  --bs-danger-rgb: 255, 77, 91;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 0, 0, 0;
  --bs-font-sans-serif: sofia-pro, system-ui, -apple-system, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-border-color: #a7abaf;
  --bs-link-color: #84bd23;
  --bs-link-hover-color: #6a971c;

  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

  --dfs-dropdown-item-padding-x: 0.5rem;
  --dfs-dropdown-item-padding-y: 0.7rem;

  --marketing-font-size: 1.25rem;
  --marketing-bs-border-radius: 5px;
  --marketing-bs-border-radius-lg: 1rem;

  --primary-color: var(--bs-primary);
  --font-family: var(--bs-font-sans-serif);
}

.banner-custom-title {
  font-size: medium;
  font-weight: bold;
}

.banner-custom-message {
  font-size: small;
  padding: 0%;
}

body {
  height: 100%;
}
body {
  margin: 0;
}

#page-content-wrapper {
  width: 100%;
  /*position: absolute;*/
  padding: 15px;
  margin-right: -200px;
}
@media (min-width: 768px) {
  #page-content-wrapper {
    padding: 20px;
    position: relative;
    margin-right: 0;
  }
}
